@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: colore 1s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes colore {
  from {
    background-color: white;
  }

  to {
    background-color: #F1F8FF;
  }
}


.mobile-title {
  font-family: Montserrat !important;
  font-weight: 800 !important;
  font-size: 2.5em !important;
  line-height: 1.2 !important;
  color: #484848;
}

.title {
  font-family: Montserrat !important;
  font-weight: 800 !important;
  font-size: 2.7em !important;
  line-height: 1.2 !important;
  color: #484848;
}


.subtitle-no-space {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 2.1em !important;
  line-height: 1.2 !important;
  color: #484848;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.subtitle {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 2.1em !important;
  line-height: 1.2 !important;
  color: #484848;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

.accented-title {
  font-family: Montserrat !important;
  font-weight: 800 !important;
  font-size: 2.7em !important;
  color: #5A81FB;
  line-height: 1.2 !important;
}

.centered-text {
  text-align: center
}

.right-column {
  text-align: right
}

.rownopadding {
  margin-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.row-low-margin {
  margin-bottom: 10px !important;
}

.leaderboard-text {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1.7em !important;
  color: #484848;
  text-transform: capitalize;
  line-height: 1.2 !important;
}


.winner-text {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1.3em !important;
  color: #484848;
  text-transform: capitalize;
  line-height: 1.2 !important;
}


.card-subtitle {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1em !important;
  color: #484848;
  line-height: 1.2 !important;
  margin-top: 5px !important;
  margin-bottom: 40px !important;
}


.card-title {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1.9em;
  color: #484848;
  text-transform: capitalize;
  line-height: 1.2 !important;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.iconagrigia {
  color: #484848;
}

.image-upload {
  width: auto;
  height: 140px;
}

html {
  scroll-behavior: smooth;
}

.image-upload>input {
  display: none;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #9EB6FF;
  background-color: white;
  padding: 10px;
  border-radius: 18px;
}

.image {
  background-color: #f2f2f2;
  max-width: 190px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
  transition: 0.5s ease-in-out;
  background-color: #f2f2f2;
}


.MuiAvatar-colorDefault {
  font-weight: 700;
  font-family: Montserrat !important;
  background-color: white !important;
  color: black !important;

  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
}


.MuiChip-label {
  padding-right: 0px !important;
}

.myButton {
  background-color: #44c767;
  border-radius: 42px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 19px 5px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
}

.card-image {
  object-fit: cover;
  max-height: 250px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
  transition: 0.5s ease-in-out;
}

.card-image:hover {
  border-radius: 30px;
}

.card-container {
  margin-bottom: 25px !important;
}

.card-column {
  padding-right: 25px !important;
}

.marginbottomzero {
  margin-bottom: 0px !important;
}

.visible {
  visibility: visible !important;
}

.height100 {
  height: 100% !important;
}

.width100 {
  width: 100% !important;
}

.width1002 {
  width: 100% !important;
  padding: 0 0.75em 0 0 !important;
}

.margintop {
  margin-top: 10px !important;
}

.margintopzero {
  margin-top: 0px !important;
}

.boldish-text {
  font-family: Montserrat !important;
  font-weight: 600 !important;
}

.low-opacity {
  opacity: .4;
}

.progress {
  margin-top: 30px !important;
  margin-bottom: -32px !important;
  opacity: 0.4;
  height: 20px !important;
  visibility: hidden;
}

.magnifier-image {
  object-fit: cover !important;
  border-radius: 40px !important;
}

.new-button-form {
  overflow: hidden;
  height: 420px !important;
  margin-top: 10px;
  letter-spacing: 0.1em !important;
  width: 100% !important;
  background-color: #FFFFFF !important;
  border-radius: 40px !important;
  padding: 0px !important;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1.3em !important;
  color: white;
  line-height: 1.2 !important;

  transition: 0.5s ease-in-out !important;
  visibility: visible !important;
}

.new-button-form-container {
  padding: 0px 25px 0px 25px !important;
}


.new-button-form-mobile {
  overflow: hidden;
  height: 280px !important;

  margin-top: 10px;
  letter-spacing: 0.1em !important;
  padding: 0px 25px 0px 25px !important;
  width: 100% !important;
  background-color: #FFFFFF !important;
  border-radius: 40px !important;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1.3em !important;
  color: white;
  line-height: 1.2 !important;

  transition: 0.5s ease-in-out !important;
  visibility: visible !important;
}

.centrato {
  display: table;
  margin: 0 auto;
}

.MuiSelect-select:focus {
  background-color: #FFFFFF !important;
}

.btn {
  text-transform: none !important;
}

.input-field input:focus+label {
  color: #5a81fb !important;
}

.input-field {
  margin-bottom: 0px !important;
}

.row .input-field {
  width: 100% !important;
}


.row .input-field input:focus {
  border-bottom: 1px solid #5a81fb !important;
  box-shadow: 0 1px 0 0 #5a81fb !important
}

ul.dropdown-content.select-dropdown li span {
  color: #5a81fb;
  /* no need for !important :) */
}

.button-confirm {
  height: auto !important;

  letter-spacing: 0.1em !important;
  padding: 15px !important;
  width: 100% !important;
  background-color: #9EB6FF !important;
  border-radius: 40px !important;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1em !important;
  color: white;
  text-transform: uppercase;
  line-height: 1.2 !important;

  transition: 0.5s ease-in-out !important;
  visibility: visible !important;
}


.button-confirm-mobile {
  height: auto !important;

  margin-top: 10px;
  letter-spacing: 0.1em !important;
  padding: 15px !important;
  width: 100% !important;
  background-color: #9EB6FF !important;
  border-radius: 40px !important;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1em !important;
  color: white;
  text-transform: uppercase;
  line-height: 1.2 !important;

  transition: 0.5s ease-in-out !important;
  visibility: visible !important;
}

.new-button {
  height: 60px !important;

  margin-top: 10px;
  letter-spacing: 0.1em !important;
  padding: 15px !important;
  width: 100% !important;
  background-color: #9EB6FF !important;
  border-radius: 40px !important;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1.3em !important;
  color: white;
  text-transform: uppercase;
  line-height: 1.2 !important;

  transition: 0.5s ease-in-out !important;
  visibility: visible !important;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.new-button:hover {
  border-radius: 20px !important;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.15) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.15) !important;
}


.match-card {
  visibility: visible !important;
  border-radius: 50px !important;
  padding-left: 10px;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
}

.leaderboardCard {
  border-radius: 50px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.03) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.03) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.03) !important;
}


.row-low-margin {
  margin-bottom: 10px !important;
}

.leaderboard-first {
  background-color: #E6E6E6;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 20px;
}

.hero-card-mobile {
  border-radius: 50px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 40px !important;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
}

.hero-card {
  border-radius: 50px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px !important;
  margin-top: 40px !important;
  -webkit-box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 18px 4px rgba(255, 0, 0, 0.05) !important;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.05) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}